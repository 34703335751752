export const GA_TRACKING_ID = 'AW-978593762' // This is your GA Tracking ID
export const FB_TRACKING_ID = '2100570743583209'

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
    return
  }
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url
  })
  window.fbq('track', 'PageView')
}

export const event = (action, id) => {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
    return
  }
  window.gtag('event', action, {
    send_to: `${GA_TRACKING_ID}/${id}`
  })
}

export const analytics = () => {
  return process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' &&
    <>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });

            !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${FB_TRACKING_ID}');
          `
        }}
      />
    </>
}
