import style from './Form.module.css'

import useTranslation from 'next-translate/useTranslation'
import Router from 'next/router'
import classNames from 'classnames/bind'
import { getLayout } from '../components/sg/Layout'
import Form, { Step } from '../components/Form'

export default function VerifyRequire () {
  const c = classNames.bind(style)
  const { t } = useTranslation('main')

  return (
    <Form
      title={t('verify-required-title')}
    >
      <Step>
        <div className={c('welcome')}>
          <img src='/images/tour-logo.svg' style={{ margin: '0 -.3em 1em', borderRadius: '.5em', objectFit: 'cover', width: 'calc(100% + .6em)', height: '100px' }} />
          <p className={c('subtext')}>{t('verify-required-message')}</p>
          <br />
          <button onClick={() => Router.push('/upload')}>{t('portfolio-expired-proceed')}</button>
          <br /><br />
        </div>
      </Step>
    </Form>
  )
}

VerifyRequire.requiresAuth = true
VerifyRequire.getLayout = getLayout
